import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardBody,
  Collapse,
  Button,
  CardTitle,
  Row,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import Pagination from "../../../components/Paginationv3";

import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

import ModalNewSplit from "./modalNewSplit";
import ModalEditSplit from "./modalEditSplit";

import {
  listSplits,
  deleteSplit,
  listAccounts,
} from "../../../utils/services/payment";

function Split(props) {
  const location = useLocation();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [splits, setSplits] = useState([]);
  const [countData, setCountData] = useState(0);

  const [deleteProductId, setDeleteProductId] = useState("");
  const [deleteProductDesc, setDeleteProductDesc] = useState("");

  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setdynamicDescription] = useState("");

  const [errorAlert, setErrorAlert] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  const [modalNewTicket, setModalNewTicket] = useState(false);
  const [modalEditTicket, setModalEditTicket] = useState(false);

  const [ticketEdit, setTicketEdit] = useState();

  const percentFormmater = new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const centsFormmater = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    useGrouping: false,
  });

  async function handleListSplits() {
    await listSplits({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    })
      .then((response) => {
        const splits = response.splits;
        setSplits(splits);
        setCountData(response.total);
      })
      .catch((error) => {
        setSplits([]);
        setCountData(0);
      });
  }

  async function handleDeleteProduct() {
    await deleteSplit({
      id: deleteProductId,
      jwt: props.state.global_user_data.data.token,
    })
      .then(async (res) => {
        if (res === 204) {
          handleListSplits();
          setConfirmAlert(false);
          setSuccessDlg(true);
          setDynamicTitle("Deletado");
          setdynamicDescription("Split Deletado");
        }
      })
      .catch((error) => {
        setErrorAlert(true);
      });
  }
  async function handleEditClick(product) {
    console.log(product);
    setTicketEdit(product);
    setModalEditTicket(true);
  }

  async function handleDeleteClick(id, code) {
    setDeleteProductId(id);
    setDeleteProductDesc(code);
    setErrorAlert(false);
    setConfirmAlert(true);
  }

  useEffect(() => {
    handleListSplits();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalNewTicket && (
        <ModalNewSplit
          isOpen={modalNewTicket}
          toggle={() => setModalNewTicket(!modalNewTicket)}
          idEvent={location.state.idEvent}
          list={handleListSplits}
          total={countData}
        />
      )}
      {modalEditTicket && (
        <ModalEditSplit
          isOpen={modalEditTicket}
          toggle={() => setModalEditTicket(!modalEditTicket)}
          idEvent={location.state.idEvent}
          list={handleListSplits}
          split={ticketEdit}
        />
      )}
      {confirmAlert ? (
        <SweetAlert
          title={`Deseja deletar ${deleteProductDesc}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeleteProduct()}
          onCancel={() => setConfirmAlert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : errorAlert ? (
        <SweetAlert
          title={`Não foi possível excluir o split: ${deleteProductDesc}`}
          error
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="danger"
          onCancel={() => {
            setErrorAlert(false);
          }}
        ></SweetAlert>
      ) : null}

      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>Splits</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <i
                    onClick={() => setModalNewTicket(true)}
                    className="mdi mdi-plus"
                    style={{
                      color: "green",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                  />
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                onClick={() => {
                  if (countData === 0) {
                    setModalNewTicket(true);
                  }
                }}
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                Adicionar Split
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th style={{ verticalAlign: "top" }}>
                    Conta que irá receber o split
                  </th>
                  <th style={{ verticalAlign: "top" }}>
                    Centavos a serem cobrados da fatura
                  </th>
                  <th style={{ verticalAlign: "top" }}>
                    Porcentagem a ser cobrada da fatura
                  </th>
                  <th style={{ verticalAlign: "top" }}>
                    Valor em centavos a ser cobrado apenas em transações no
                    cartão de crédito
                  </th>
                  <th style={{ verticalAlign: "top" }}>
                    Valor em porcentagem a ser cobrado apenas em transações no
                    cartão de crédito
                  </th>
                  <th style={{ verticalAlign: "top" }}>
                    Valor em centavos a ser cobrado apenas em transações no pix
                  </th>
                  <th style={{ verticalAlign: "top" }}>
                    Valor em porcentagem a ser cobrado em transações no pix
                  </th>
                  <th
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {splits.map((split, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {split.recipient_account_id}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {centsFormmater.format(split.cents)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {percentFormmater.format(split.percent)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {centsFormmater.format(split.credit_card_cents)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {percentFormmater.format(split.credit_card_percent)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {centsFormmater.format(split.pix_cents)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        {percentFormmater.format(split.pix_percent)}
                      </td>
                      {/* <td>
                        {ticket.quantityAvailable === -1
                          ? 'Ilimitada'
                          : ticket.quantityAvailable}
                      </td>
                      <td>{monetaryFormatter.format(ticket.price)}</td>
                      <td>{ticket.attendeeEligible ? 'Sim' : 'Não'}</td> */}
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => handleEditClick(split)}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDeleteClick(
                              split.id,
                              split.recipient_account_id
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={splits}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          countData={countData}
          maxPageButtonsToShow={3}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Split);
