import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import SectionComponent from "../../Apps/Personalization/SectionComponent";
import ModalNewCertificate from "./ModalNewCertificate";
import ModalEditCertificate from "./ModalEditCertificate";
import Pagination from "../../../components/Paginationv3";
import {
  deleteCertificate,
  getAllCertificate,
} from "../../../utils/services/certificate";
import { useSelector } from "react-redux";
import { deleteFile } from "../../../utils/services/files";

import { useLocation } from "react-router-dom";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";
import { urlDomain } from "../../../utils/services/config";

const columnsFilter = [
  { name: "Nome", value: "name", type: "text" },
  { name: "Tag", value: "tag", type: "text" },
  { name: "Data de Criação", value: "createdat", type: "date" },
  { name: "Últ. Atualização", value: "updatedat", type: "date" },
];

export default function Certificate(props) {
  const location = useLocation();

  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  //search and paginations
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  //new certificate
  const [modalNewCertificate, setModalNewCertificate] = useState(false);
  //edit certificate
  const [modalEditCertificate, setModalEditCertificate] = useState(false);
  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);

  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState({});

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  const debouncedSearch = useDebounce(search, 300);

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  function toggleNewCertificate() {
    setModalNewCertificate(!modalNewCertificate);
  }

  function toggleEditCertificate() {
    setModalEditCertificate(!modalEditCertificate);
  }

  const formatDate = (date) => {
    try {
      const formatedDate = date.split("T")[0].split("-").reverse().join("/");
      const formatTime = date.split("T")[1].split(".")[0];

      return `${formatedDate} ${formatTime}`;
    } catch (error) {
      return date;
    }
  };

  const getCertificates = useCallback(async () => {
    const response = await getAllCertificate({
      jwt,
      eventId: location.state.idEvent,
      offset: currentPage,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    setCertificates(response.data?.data?.certificates || []);
    setTotalItems(response.data?.data?.total);
  }, [jwt, location.state.idEvent, currentPage, itemsPerPage, debouncedSearch]);

  const excludeCertificate = useCallback(async () => {
    await deleteCertificate({ id: selectedCertificate.id, jwt });

    await deleteFile(selectedCertificate.imageId, jwt);

    setsuccess_dlg(true);
    getCertificates();
  }, [jwt, selectedCertificate, getCertificates]);

  useEffect(() => {
    getCertificates();
  }, [getCertificates]);

  return (
    <div>
      {modalNewCertificate && (
        <ModalNewCertificate
          isOpen={modalNewCertificate}
          toggle={toggleNewCertificate}
          eventId={location.state.idEvent}
          refetch={getCertificates}
        />
      )}
      {modalEditCertificate && (
        <ModalEditCertificate
          isOpen={modalEditCertificate}
          toggle={toggleEditCertificate}
          eventId={location.state.idEvent}
          refetch={getCertificates}
          certificate={selectedCertificate}
        />
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Deseja deletar esse certificado?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            excludeCertificate();
            setconfirm_alert(false);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          Certificado deletado com sucesso
        </SweetAlert>
      ) : null}
      <Card>
        <CardBody>
          <CardTitle>Certificado</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type={
                    activeFilter === "createdat" || activeFilter === "updatedat"
                      ? "date"
                      : "text"
                  }
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "createdat" ||
                        activeFilter === "updatedat"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewCertificate(true)}>
                    Criar Certificado
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewCertificate(true)}
              >
                Criar Certificado
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Certificado</th>
                  <th>TAG</th>
                  <th>Data de Criação</th>
                  <th>Data da Última Atualização</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {certificates.length > 0 &&
                  certificates.map((e, i) => (
                    <tr key={i}>
                      <td>{e.name}</td>
                      <td>
                        {e.tags.map((tag) => (
                          <span className="tagDriver">{tag.name + " "}</span>
                        ))}
                      </td>
                      <td>{formatDate(e.createdAt)}</td>
                      <td>{formatDate(e.updatedAt)}</td>
                      <td>
                        <i
                          className="mdi mdi-content-copy"
                          style={{
                            color: "grey",
                            fontSize: 17,
                            margin: "0 3px",
                            cursor: "pointer",
                          }}
                          title="Copiar"
                          onClick={() =>
                            clipBoard(
                              `https://certificado.${urlDomain}/?idEvent={EVENTOID}&idPart={PAXID}&idCertificate=${e.id}&namePart={PAXNOME}&native=true`
                            )
                          }
                        />
                        <a
                          href={`https://certificado.${urlDomain}/?idEvent={EVENTOID}&idPart={PAXID}&idCertificate=${e.id}&namePart={PAXNOME}&native=true`}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Visualizar Certificado"
                        >
                          <i
                            className="mdi mdi-eye"
                            style={{
                              color: "blue",
                              fontSize: 17,
                              margin: "0 3px",
                              cursor: "pointer",
                            }}
                          />
                        </a>
                        <i
                          className="mdi mdi-pencil"
                          title="Editar"
                          style={{
                            margin: "0 3px",
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setModalEditCertificate(true) >
                            setSelectedCertificate(e)
                          }
                        />
                        <i
                          className="mdi mdi-trash-can"
                          title="Excluir"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setconfirm_alert(true) > setSelectedCertificate(e)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={certificates}
          search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
