import writeXlsxFile from "write-excel-file";
import { getTransfer } from "../../../utils/services/transfer";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return `${formatedDate}`;
  };

  const schema = [
    {
      column: "Id Transfer",
      type: String,
      value: (participant) => participant.id,
    },
    {
      column: "Id Participante",
      type: String,
      value: (participant) => participant.login.id,
    },
    {
      column: "Email Participante",
      type: String,
      value: (participant) => participant.login.email,
    },
    {
      column: "Participante",
      type: String,
      value: (participant) => participant.login.nome,
    },
    {
      column: "Empresa",
      type: String,
      value: (participant) => participant.company,
    },
    {
      column: "Origem",
      type: String,
      value: (participant) => participant.origin,
    },
    {
      column: "Destino",
      type: String,
      value: (participant) => participant.destination,
    },
    {
      column: "Data/hora embarque",
      type: String,
      value: (participant) =>
        `${formatDate(participant.boardingdate)} ${participant.boardingtill}`,
    },
    {
      column: "Data/hora chegada",
      type: String,
      value: (participant) =>
        participant?.arrivaldate ? `${formatDate(participant.arrivaldate)} ${participant.arrivaltime}` : '',
    },
    {
      column: "Ponto de Encontro",
      type: String,
      value: (participant) => participant.meetingpoint,
    },
    {
      column: "Nome do Motorista",
      type: String,
      value: (participant) => participant.drivername,
    },
    {
      column: "Telefone do Motorista",
      type: String,
      value: (participant) => participant.driverphone,
    },
    {
      column: "Informações Extras",
      type: String,
      value: (participant) => participant.extrainformation,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getTransfer({
      jwt,
      idEvent,
      offset: i,
      limit,
      search: "",
      });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");
    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneTransferData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "transfer.xlsx",
    });
  }
}
