import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//paginação
import Pagination from "../../../components/Paginationv3";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import { deleteForm, getAllForms } from "../../../utils/services/rsvp";
import DuplicateForm from "./duplicateForm";

export default function Rsvp(props) {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  //pesquisa
  const [search, setSearch] = useState("");
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  //deletar
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [idDelete, setIdDelete] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  // collapse btn
  const [isOpen, setIsOpen] = useState(false);

  // duplicate
  const [isOpenDuplicate, setIsOpenDuplicate] = useState(false);

  const [selectedForm, setSelectedForm] = useState([]);

  const [forms, setForms] = useState([]);

  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("-");
    return formatedDate;
  };

  const allForms = useCallback(async () => {
    const response = await getAllForms({
      eventId: props.eventId,
      jwt,
      offset: currentIndex,
      limit: itemsPerPage,
      search,
    });
    // const data = response.data.forms.filter((form) => Array.isArray(JSON.parse(form.json)));
    if (response) {
      setCountData(response.data.total);
      setForms(response.data.forms || []);
    }
  }, [currentIndex, itemsPerPage, search]);

  const deleteSelectedForm = async () => {
    await deleteForm({ jwt, id: idDelete });

    setsuccess_dlg(true);
    setconfirm_alert(false);
    await allForms();
  };

  useEffect(() => {
    allForms();
  }, [allForms]);

  return (
    <div>
      <DuplicateForm
        isOpen={isOpenDuplicate}
        toggle={() => setIsOpenDuplicate(false)}
        selectedForm={selectedForm}
        updateList={allForms}
      />
      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar esse formulário?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteSelectedForm();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          Formulário deletado com sucesso
        </SweetAlert>
      ) : null}
      <Card>
        <CardBody>
          <CardTitle>Formulário Dinâmico</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => props.setStep("_b")}>
                    Criar Formulário
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => {
                  props.setStep("_b");
                }}
              >
                Criar Formulário
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Data de criação</th>
                  <th>Última atualização</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {forms.length > 0 &&
                  forms.map((form) => (
                    <tr key={form.id}>
                      <td>{form.id}</td>
                      <td>{form.description}</td>
                      <td>{formatDate(form.createdAt)}</td>
                      <td>{formatDate(form.updatedAt)}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          onClick={() =>
                            props.setStep("_c") > props.setSelectedForm(form)
                          }
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                        />{" "}
                        <i
                          title="Duplicar formulário"
                          className="mdi mdi-content-duplicate"
                          onClick={() =>
                            setSelectedForm(form) > setIsOpenDuplicate(true)
                          }
                          style={{
                            color: "gray",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                        />{" "}
                        <i
                          className="mdi mdi-eye"
                          onClick={() =>
                            props.setStep("_d") > props.setSelectedForm(form)
                          }
                          style={{
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIdDelete(form.id);
                            setconfirm_alert(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 8 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        ** countData - quantidade total de itens
        ** maxItemPerPage - estado quantidade de de itens por página
        ** setMaxItemPerPage - função que troca o estado com a quantidade maxima de itens por página 
        ** maxPageButtonsToShow - quantidade de botões qua vão aparecer para a pessoa clicar, somente números impares
        ** 5 = vai de 1 até 5 / 7 vai de 1 até 7 
        */}
        <Pagination
          data={[]}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
