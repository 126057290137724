import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// select
import Select from "react-select";
//service
import { updateHotel } from "../../../utils/services/hotel";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewHotel(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  const participants = props.participants;
  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [addresError, setAddresError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = async (e) => {
    //tratamento de erros
    if (!participantSelected) {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (e.target[2].value === "") {
      setCompanyError("O campo Hotel é obrigatório");
      return;
    } else setCompanyError("");

    if (e.target[3].value === "") {
      setDateBoardingError("O campo Data checkIn é obrigatório");
      return;
    } else setDateBoardingError("");

    if (e.target[4].value === "") {
      setTimeBoardingError("O campo Hora checkIn é obrigatório");
      return;
    } else setTimeBoardingError("");

    if (e.target[5].value === "") {
      setDateArrivalError("O campo Data checkOut é obrigatório");
      return;
    } else setDateArrivalError("");

    if (e.target[6].value === "") {
      setTimeArrivalError("O campo Hora checkOut é obrigatório");
      return;
    } else setTimeArrivalError("");

    if (e.target[8].value === "") {
      setAddresError("O campo Endereço é obrigatório");
      return;
    } else setAddresError("");

    setIsloading(true);

    await updateHotel({
      event: props.idEvent,
      login: participantSelected.value,
      hotel: e.target[2].value,
      checkindate: e.target[3].value,
      checkoutdate: e.target[5].value,
      checkintime: e.target[4].value,
      checkouttime: e.target[6].value,
      reservationnumber: e.target[7].value,
      address: e.target[8].value,
      phonenumber: e.target[9].value,
      extrainformation: e.target[10].value,
      jwt: props.state.global_user_data.data.token,
      idHotel: props.editHotel.id,
    })
      .then((response) => {
        setIsloading(false);
        setsuccess_dlg(true);
      })
      .catch((error) => {
        setIsloading(false);
        // console.log("error", error);
      });
  };

  function funcParticipants() {
    const userSelected = props.editHotel.login;

    setParticipantSelected({
      label: userSelected.nome,
      value: userSelected.id,
    });
  }

  useEffect(() => {
    funcParticipants();
  }, [participants]);

  useEffect(() => {}, [participants]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Hotel editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Hotel</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Hotel</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editHotel.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <Select
                placeholder={"Selecione um participantes..."}
                value={participantSelected}
                onChange={(e) => {
                  setParticipantSelected(e);
                }}
                options={[
                  {
                    options: [
                      ...participants.map((e) => {
                        return { label: e.nome, value: e.id };
                      }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
              />
              {participantError && (
                <a style={{ color: "red" }}>{participantError}</a>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Hotel*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editHotel.hotel}
                />
              </div>
              {companyError && <a style={{ color: "red" }}>{companyError}</a>}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data checkIn*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editHotel.checkindate}
                  id="example-datetime-local-input"
                />
              </div>
              {dateBoardingError && (
                <a style={{ color: "red" }}>{dateBoardingError}</a>
              )}
            </Col>

            <Col lg={6}>
              <div>
                <label className="form-label">Hora checkIn*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editHotel.checkintime}
                  id="example-datetime-local-input"
                />
              </div>
              {timeBoardingError && (
                <a style={{ color: "red" }}>{timeBoardingError}</a>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data checkOut*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                  defaultValue={props.editHotel.checkoutdate}
                />
              </div>
              {dateArrivalError && (
                <a style={{ color: "red" }}>{dateArrivalError}</a>
              )}
            </Col>

            <Col lg={6}>
              <div>
                <label className="form-label">Hora checkOut*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editHotel.ckeckouttime}
                  id="example-datetime-local-input"
                />
              </div>
              {timeArrivalError && (
                <a style={{ color: "red" }}>{timeArrivalError}</a>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Número da reserva</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editHotel.reservationnumber}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Endereço*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editHotel.address}
                />
              </div>
              {addresError && <a style={{ color: "red" }}>{addresError}</a>}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Telefone do hotel</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editHotel.phonenumber}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editHotel.extrainformation}
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewHotel);
