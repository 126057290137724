import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Button,
  CardTitle,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import ButtonDisplay from "./inputs/ButtonDisplay";
import CaptchaDisplay from "./inputs/Captcha";

import CellphoneDisplay from "./inputs/CellphoneDisplay";
import CheckboxDisplay from "./inputs/CheckboxDisplay";
import CPFDisplay from "./inputs/CPFDisplay";
import EmailDisplay from "./inputs/EmailDisplay";
import LabelDisplay from "./inputs/LabelDisplay";
import PasswordDisplay from "./inputs/PasswordDisplay";
import RadioDisplay from "./inputs/RadioDisplay";
import SelectDisplay from "./inputs/SelectDisplay";
import TextDisplay from "./inputs/TextDisplay";
import ThermDisplay from "./inputs/ThermDisplay";
import UploadDisplay from "./inputs/UploadDisplay";

export default function ViewForm(props) {
  const { selectedForm } = props;

  const [form, setForm] = useState([]);
  const [saveForm, setSaveForm] = useState(false);

  // collapse btn
  const [isOpen, setIsOpen] = useState(false);

  const handleSave = () => {
    setSaveForm(true);
    setTimeout(() => {
      setSaveForm(false);
    }, 5000);
  };

  const components = useCallback(
    (attributes, rowId, colId) => [
      <TextDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <UploadDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <ThermDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <SelectDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <CheckboxDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <RadioDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <ButtonDisplay attributes={attributes} saveForm={() => handleSave()} />,
      <EmailDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <CellphoneDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <CPFDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <LabelDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
      <CaptchaDisplay rowId={rowId} colId={colId} setValue={setForm} />,
      <PasswordDisplay
        attributes={attributes}
        rowId={rowId}
        colId={colId}
        setValue={setForm}
      />,
    ],
    []
  );

  useEffect(() => {
    if (selectedForm.json) {
      const json = JSON.parse(selectedForm.json);
      setForm(json);
    }
  }, [selectedForm.json]);

  return (
    <div>
      <Card>
        <CardTitle>Visualizar</CardTitle>
        <CardBody>
          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <SectionComponent
              sectionName="Opções"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            <Collapse isOpen={isOpen}>
              <Row className="mobile-btns-top">
                <div onClick={() => props.setStep("_a")}>Voltar</div>
              </Row>
            </Collapse>
          </div>
          <div className="header-btns-container">
            <Button
              color="danger"
              style={{ height: 40, width: 80 }}
              onClick={() => {
                props.setStep("_a");
              }}
            >
              Voltar
            </Button>
          </div>
          {form.length > 0 &&
            form.map((item, index) => (
              <Row className="row-wrapper-form" key={index}>
                {item.cols.map((col) => (
                  <Col key={`row/${item.row}/col${col.col}`}>
                    {components(col.attributes, item.row, col.col)[col.inputId]}
                    {((saveForm &&
                      col.attributes.required &&
                      col.attributes.errorMessage) ||
                      (saveForm &&
                        col.attributes.hasValidation &&
                        col.attributes.errorMessage)) && (
                      <span style={{ color: "red" }}>
                        {col.attributes.errorMessage}
                      </span>
                    )}
                  </Col>
                ))}
              </Row>
            ))}
        </CardBody>
      </Card>
    </div>
  );
}
