import React, { useState, useRef, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
//redux
import { connect } from "react-redux";

//paginação
import Pagination from "../../../components/Paginationv3";

//useQuery
import { useQueries } from "react-query";

//componentes
import ModalNewHotel from "./ModalNewHotel";
import ModalEditHotel from "./ModalEditHotel";
import ImportHotel from "./ImportHotel";
import SearchFilters from "../../../components/SearchFilters";

//service
import {
  getHotel,
  deleteHotel,
  deleteAllHotels,
} from "../../../utils/services/hotel";
import { getParticipantsLogistic } from "../../../utils/services/participants";

import useDebounce from "../../../utils/hooks/useDebounce";
import { createXlsx } from "./createXlsx";
import WaitExport from "../../../components/WaitExport";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

const columnsFilter = [
  { name: "Participante", value: "login", type: "text" },
  { name: "Hotel", value: "hotel", type: "text" },
  { name: "Check In", value: "checkindate", type: "date" },
  { name: "Check Out", value: "checkoutdate", type: "date" },
];

function Hotel(props) {
  const location = useLocation();
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewHotel, setModalNewHotel] = useState(false);
  const [modalEditHotel, setModalEditHotel] = useState(false);
  const [modalImportHotel, setModalImportHotel] = useState(false);
  //deletar participante
  const [deleteHotelState, setDeleteHotelState] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //editar participante
  const [editHotel, setEditHotel] = useState();
  //state de hotels
  const [hotels, setHotels] = useState([]);
  //state de participants
  // const [participants, setParticipants] = useState([]);
  const participants = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [countData, setCountData] = useState(1);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const debouncedSearch = useDebounce(search, 300);

  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("login");

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function toggleNewHotel() {
    setModalNewHotel(!modalNewHotel);
  }

  function toggleEditHotel() {
    setModalEditHotel(!modalEditHotel);
  }

  function toggleImportHotel() {
    setModalImportHotel(!modalImportHotel);
  }

  function formatData(startDate) {
    var data = new Date(startDate + "T00:00:00-03:00");
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  async function confirmDelete() {
    await deleteHotel({
      jwt: props.state.global_user_data.data.token,
      id: deleteHotelState.id,
    })
      .then((response) => {
        setconfirm_alert(false);
        setsuccess_dlg(true);
        setdynamic_title("Deletado");
        setdynamic_description("Hotel deletado");
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  async function confirmDeleteAllHotels() {
    await deleteAllHotels({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
    }).then((response) => {
      setConfirmAllDisabled(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletados");
      setdynamic_description("Transfers deletados");
    });
  }
  const data = useQueries([
    {
      queryKey: ["getAllParticipants" + location.state.idEvent, 1],
      queryFn: async () => {
        const partForm = await getParticipantsLogistic({
          jwt: props.state.global_user_data.data.token,
          idEvent: location.state.idEvent,
          offset: 0,
          limit: 0,
          search: "",
        });
        participants.current = partForm?.data?.data;
      },
    },
    {
      queryKey: [
        "getAllHotels" +
          location.state.idEvent +
          currentIndex +
          itemsPerPage +
          debouncedSearch,
        2,
      ],
      notifyOnChangeProps: ["currentIndex"],
      queryFn: async () => {
        const hotelsData = await getHotel({
          jwt: props.state.global_user_data.data.token,
          idEvent: location.state.idEvent,
          offset: currentIndex > 0 ? currentIndex : 1,
          limit: itemsPerPage,
          search: treatmentSearch(),
          columnSearch: activeFilter,
        });
        setCountData(hotelsData.findOneHotelData.count);
        setHotels(hotelsData.findOneHotelData.data);
      },
    },
  ]);

  useEffect(() => {
    getParticipantsLogistic({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: 0,
      limit: 0,
      search: "",
    }).then((partForm) => {
      participants.current = partForm?.data?.data;
    });
    getHotel({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    }).then((hotelsData) => {
      setCountData(hotelsData.findOneHotelData.count);
      setHotels(hotelsData.findOneHotelData.data);
    });
  }, []);

  return (
    <div>
      {modalNewHotel && (
        <ModalNewHotel
          isOpen={modalNewHotel}
          toggle={toggleNewHotel}
          idEvent={location.state.idEvent}
          data={data}
          participants={participants?.current}
        />
      )}
      {modalEditHotel && (
        <ModalEditHotel
          isOpen={modalEditHotel}
          toggle={toggleEditHotel}
          idEvent={location.state.idEvent}
          data={data}
          editHotel={editHotel}
          participants={participants.current}
        />
      )}
      <ImportHotel
        isOpen={modalImportHotel}
        toggle={toggleImportHotel}
        token={props.state.global_user_data.data.token}
        idEvent={location.state.idEvent}
        data={data}
      />
      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar hospedagem em ${deleteHotelState.hotel}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}
      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todos os Hotéis?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAllHotels();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          <span>Você não poderá reverter essa ação.</span>
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            data[0].refetch();
            data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <WaitExport
        isOpen={exportInProgress}
        name="Hoteis"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Hotel</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type={
                    activeFilter === "checkindate" ||
                    activeFilter === "checkoutdate"
                      ? "date"
                      : "text"
                  }
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "checkindate" ||
                        activeFilter === "checkoutdate"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {hotels.length > 0 && (
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() =>
                        createXlsx({
                          jwt: props.state.global_user_data.data.token,
                          idEvent: location.state.idEvent,
                          limit: 100,
                          countData,
                          setFinishExport,
                        }) > setExportInProgress(true)
                      }
                    >
                      Exportar Hotel
                    </div>
                  </Row>
                )}
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalImportHotel(!modalImportHotel)}>
                    Importar Hotel
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewHotel(true)}>
                    Adicionar Hotel
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              {hotels?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createXlsx({
                      jwt: props.state.global_user_data.data.token,
                      idEvent: location.state.idEvent,
                      limit: 100,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Hotel
                </Button>
              )}
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalImportHotel(!modalImportHotel)}
              >
                Importar Hotel
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewHotel(true)}
              >
                Adicionar Hotel
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Participante</th>
                  <th>Hotel</th>
                  <th>Data/hora checkIn</th>
                  <th>Data/hora checkOut</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {hotels &&
                  hotels
                    ?.filter(
                      (e) => e.login.nome?.toLocaleLowerCase()
                      // ?.indexOf(search.toLocaleLowerCase()) > -1
                    )
                    ?.map((e, i) => (
                      <tr key={i}>
                        <td>
                          {/* {participants.map((a) => {
                            if (a.id === e.login) {
                              return a.nome;
                            }
                          })} */}
                          {e.login.nome}
                        </td>
                        <td>{e.hotel}</td>
                        <td>
                          {formatData(e.checkindate)} {e.checkintime}
                        </td>
                        <td>
                          {formatData(e.checkoutdate)} {e.ckeckouttime}
                        </td>
                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditHotel(e);
                              setModalEditHotel(!modalEditHotel);
                            }}
                          />{" "}
                          <i
                            className="mdi mdi-trash-can"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDeleteHotelState(e);
                              setconfirm_alert(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div
        className="bottom-navigation"
        style={{
          justifyContent: `${hotels?.length > 0 ? "space-between" : "end"}`,
        }}
      >
        {hotels?.length > 0 && (
          <Button
            color="danger"
            className="btn-rounded waves-effect waves-light events-button-add"
            onClick={() => setConfirmAllDisabled(true)}
          >
            Limpar Todos Hotéis
          </Button>
        )}
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={hotels}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Hotel);
