import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import readXlsxFile from "read-excel-file";
import { createFlightImport } from "../../../utils/services/flight";
import ModeloUpload from "../../../assets/statics/modelo_voo.xlsx";
import { formatBytes } from "../../../utils/helpers/files/formatBytes";
import {
  allExcelColumns,
  excelColumnsPtBr,
  findRowsWithError,
  getUnregisteredEmails,
} from "./importValidation";
import ModalExcelRows from "./ModalExcelRows";

export default function ImportFlight(props) {
  const { isOpen, toggle, idEvent, token } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [imgError, setImgError] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  const importActivated = useRef(false);

  // modal de erros no arquivo xlsx
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);

  let timerID = null;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  useEffect(() => {
    setselectedFiles([]);
    setImgError("");
  }, [isOpen]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
    clearTimeout(timerID);
  }

  function cancelImport() {
    importActivated.current = false;
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  async function uploadService() {
    importActivated.current = true;
    clearTimeout(timerID);
    setBtnSend(false);
    setBtnCancel(true);
    const schema = {
      Participante: {
        prop: "login",
        type: String,
      },
      DataEmbarque: {
        prop: "boardingdate",
        type: Date,
      },
      HoraEmbarque: {
        prop: "boardingtill",
        type: String,
      },
      DataChegada: {
        prop: "arrivaldate",
        type: Date,
      },
      HoraChegada: {
        prop: "arrivaltime",
        type: String,
      },
      Companhia: {
        prop: "company",
        type: String,
      },
      Origem: {
        prop: "origin",
        type: String,
      },
      Destino: {
        prop: "destination",
        type: String,
      },
      Localizador: {
        prop: "locator",
        type: String,
      },
      Eticket: {
        prop: "eticket",
        type: String,
      },
      NumeroVoo: {
        prop: "flightnumber",
        type: String,
      },
      Terminal: {
        prop: "terminal",
        type: String,
      },
      Assento: {
        prop: "seat",
        type: String,
      },
      GrupoDeEmbarque: {
        prop: "boardinggroup",
        type: String,
      },
      InformacoesExtras: {
        prop: "extrainformation",
        type: String,
      },
    };

    let count = 1;
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    const readXlsx = async () => {
      const { rows } = await readXlsxFile(selectedFiles[0], { schema });
      const validateRows = rows.map((row, i) => allExcelColumns(row, i));
      const rowsWithError = findRowsWithError(validateRows);

      setTotalRows(validateRows.length);

      if (rowsWithError.length > 0 || rows.length === 0) {
        setErrors(rowsWithError);
        setModalErrors(true);
        return;
      }

      const unregisteredEmails = await getUnregisteredEmails(
        validateRows,
        token,
        idEvent
      );

      if (unregisteredEmails.length > 0) {
        setErrors(unregisteredEmails);
        setModalErrors(true);
        return;
      }

      validateRows.forEach(async (row, i) => {
        await sleep(100);
        importFlightsService(row, i, validateRows.length);
      });
    };

    readXlsx();

    async function importFlightsService(row, index, totalRows) {
      await createFlightImport({
        event: idEvent,
        login: row.login,
        company: row.company,
        locator: row.locator,
        origin: row.origin,
        destination: row.destination,
        boardingdate: row.boardingdate,
        arrivaldate: row.arrivaldate,
        boardingtill: row.boardingtill,
        arrivaltime: row.arrivaltime,
        terminal: row.terminal,
        seat: row.seat,
        boardinggroup: row.boardinggroup,
        extrainformation: row.extrainformation,
        eticket: row.eticket,
        flightnumber: row.flightnumber,
        jwt: token,
      }).then((e) => {
        if (e.message === "created") {
          setTotalSended(count++);
        }
        if (totalRows === ++index) {
          setBtnCancel(false);
        }
        if (count === totalRows || (count === 2 && totalRows === 1)) {
          setsuccess_dlg(true);
          props.data[0].refetch();
          props.data[1].refetch();
        }
      });
      // .catch((error) => console.log(error));
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Upload"}
          onConfirm={() => {
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"O arquivo foi enviado com sucesso!"}
        </SweetAlert>
      ) : null}

      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors}
          columnNames={excelColumnsPtBr}
        />
      )}
      <ModalHeader toggle={toggle}>Importar Voos</ModalHeader>
      <ModalBody className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <CardText className="mb-2 p-10">
              O arquivo a ser importado deve seguir este{" "}
              <a href={ModeloUpload} download="modelo_voo.xlsx">
                Modelo
              </a>
              , não modifique seu cabeçalho.
            </CardText>

            <Form>
              {selectedFiles.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-1"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-4">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <div className="dropzone-previews mt-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-1">
                        <Row className="align-items-center">
                          <Col xs="11">
                            <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                          </Col>
                          <Col xs="1">
                            <span
                              style={{
                                fontSize: "16px",
                                color: "red",
                                fontWeight: "700",
                              }}
                              className="mdi mdi-close"
                              onClick={() => setselectedFiles([])}
                            />
                          </Col>
                        </Row>
                        {importActivated.current && (
                          <Row className="align-items-center">
                            <Col>
                              <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Form>

            <div className="text-center mt-3">
              <p style={{ color: "red" }}>{imgError}</p>
              <button
                type="button"
                disabled={!btnSend}
                className="btn btn-primary waves-effect waves-light"
                onClick={() => uploadService()}
              >
                Enviar Voos
              </button>
              <button
                type="button"
                className="btn btn-danger mx-1"
                disabled={!btnCancel}
                onClick={() => cancelImport()}
              >
                Cancelar
              </button>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}
